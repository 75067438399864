import Home from './pages/home/home'
import Login from './pages/login/login'
import { BrowserRouter, Route, Routes, Navigate, useRoutes } from 'react-router-dom';

import Layout from './pages/home/layout';
import Usuarios from './pages/home/usuarios';
import { RequireAuth } from './services/RequiereAuth';
import VerDespacho from './pages/home/verdespacho';
import ListarDespachos from './pages/home/listadespachos';
import Coleccion from './pages/home/coleccion';

import { Usuario } from './services/Usuario';
import ConsultarGuia2 from './pages/home/consultarguia2';
import { UserProvider } from './components/UserContext';

function App() {
  
  const usuario = Usuario();

  return (
    <>
    <UserProvider>
      <BrowserRouter>
        <Routes >
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Navigate to="/login" />} />

          <Route path="/home" element={
          <RequireAuth>
            <Layout />
          </RequireAuth>
        } >
              <Route index path="/home" element={<Home />} />
              <Route index path="/home/usuarios" element={<Usuarios />} />
              <Route index path="/home/coleccion" element={<Coleccion />} />
              <Route index path="/home/listadodespachos" element={<ListarDespachos />} />
              <Route index path="/home/verdespachos/:idDespacho" element={<VerDespacho />} />
              <Route index path="/home/consultarguias/:idEstado" element={<ConsultarGuia2 />} />
             
          </Route>
          <Route path="/" element={<Login />} />
          <Route path="*" element={<Login />} />
        </Routes>
      </BrowserRouter>
      </UserProvider>
    </>
  );
}

export default App;
