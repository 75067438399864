import React from 'react'
import Footer from './partials/footer'
import Navbar from './partials/navbar'
import SettingPanel from './partials/setting_panel'
import Sidebar from './partials/sidebar'
import "./css/style.css";

import { Outlet, useNavigate } from 'react-router-dom'
import { NuevaGuia } from '../../components/NuevaGuia'
import { useState } from 'react'

const Layout = () => {
    const [openNuevaGuia, setOpenNuevaGuia] = useState(false);
    const navigate = useNavigate();

    const cerrar_sesion = () => {
      localStorage.removeItem('userData')
    }

    const handleOpenNuevaGuia = () => {
      setOpenNuevaGuia(true);
    }

    const buscarguia = (numguia) => {
      navigate('/home/detalleguia/'+numguia)
    }



  return (
    <>
        <div className="container-scroller">
            <Sidebar  buscarguia={buscarguia}  />
            <SettingPanel />
            <Navbar cerrar_sesion={cerrar_sesion} handleOpenNuevaGuia={handleOpenNuevaGuia} />

          <div className="main-panel">
            
              <div className="pb-0">
                
                
                {/* <!-- first row starts here --> */}
            
                  
              {/*   <Suspense fallback={<><div className="logoapp"></div><div className="loading"></div></>}>
                       <Route exact  path="/home" element={<StatusGuias />} />
                       <Route exact  path="/vehiculos" element={<Vehiculos />} />
              
              </Suspense> */}
              
           
              
             <Outlet />


              </div> {/* content-wrapper */}
            
              <Footer />

          </div> {/* main-panel */}

        </div>
        
    </>
  )
}

export default Layout