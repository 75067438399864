import { LinearProgress, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { GetData } from '../../../services/GetData';

const StatusGuias = ({id_empleado, id_tipos_usuario}) => {

    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().slice(0, 10);
    
    const [TotalFlete, setTotalFlete] = useState(0)
    const [EnRuta, setEnRuta] = useState(0)
    const [EnReexped, setEnReexped] = useState(0)
    const [Loading, setLoading] = useState(false)
    const [LoadingT, setLoadingT] = useState(false)
    const [FechaActual, setFechaActual] = useState(formattedDate)    

    const navigate = useNavigate();

    const format_number = (number) => {        
      const nf = new Intl.NumberFormat();
     return nf.format(number);  
    
   }

    useEffect(() => {
      setLoading(true)

      let urlapi = 'guias/listestadogsaux/';
      if (id_tipos_usuario === "6")
          urlapi = 'guias/listestadogsreexp/';


      GetData(urlapi+id_empleado).then ((result) =>{
        if (result.error === false){
            
            if (id_tipos_usuario === "5"){
               const {cant_estado_2, cant_estado_5} = result.estados[0];
               setEnRuta(cant_estado_2);
               setEnReexped(cant_estado_5);
            }else{
              const {cant_estado_5} = result.estados[0];
              setEnReexped(cant_estado_5);
            }

            setLoading(false)
        }
     });

     
    }, [id_empleado])

    useEffect(() => {
      setLoadingT(true)
      let urlapi = 'auxiliares/totalfletes/';
      if (id_tipos_usuario === "6")
          urlapi = 'auxiliares/totalfletesreexp/';

      console.log(id_tipos_usuario, urlapi);    
      GetData(urlapi+id_empleado+'/'+FechaActual).then ((result) =>{
        if (result.error === false){                        
            setTotalFlete(result.totalflete[0].total)
            setLoadingT(false)
        }
     });

     
    }, [FechaActual, id_empleado])
    
    const handleDateChange = (e) => {
      setFechaActual(e.target.value);
    };

   const IrA = (id) => {
         navigate('/home/consultarguias/'+id, { replace: true })
         return 0;
   }

  return (
    <>
          <div className='p-2 '>
            <div className="row justify-content-center">
             
              <div className="col-sm-6 col-xl-4 stretch-card grid-margin">
                <div className="card p-2">
                  <div className="card-body">

                  {id_tipos_usuario === "5" &&
                    <div className="d-flex border-bottom mb-4 pb-2">
                      <div className="hexagon cursor" onClick={()=>IrA(2)}>
                        <div className="hex-mid hexagon-danger">
                          <i className="mdi mdi-truck"></i>
                        </div>
                      </div>
                      <div className="pl-4">
                        <h4 className="font-weight-bold text-danger mb-0">{EnRuta}</h4>
                        <h6 className="text-muted">En Ruta</h6>
                        { Loading  &&
                            <LinearProgress />
                        }
                      </div>
                    </div>
                  }
                    
                    <div className="d-flex">
                      <div className="hexagon cursor" onClick={()=>IrA(5)}>
                        <div className="hex-mid hexagon-primary">
                          <i className="mdi mdi-timer-sand"></i>
                        </div>
                      </div>
                      <div className="pl-4">
                        <h4 className="font-weight-bold text-primary mb-0">{EnReexped}</h4>
                        <h6 className="text-muted mb-0">En Reexpedición</h6>
                        { Loading  &&
                            <LinearProgress />
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>

             
              
              <div className="col-sm-6 col-xl-4 stretch-card grid-margin">
                <div className="card p-2">
                  <div className="card-body">
                      <div className='row'>
                       
                         <div className='col-12 text-center'>
                            <TextField type='date'  id="standard-basic" label="Seleccionar Fecha" variant="standard" value={FechaActual} onChange={handleDateChange}  />                            
                         </div> 
                         <div className='col-12  mt-3'>
                            <div className="d-flex border-bottom mb-4 pb-2">
                              <div className="hexagon cursor" >
                                <div className="hex-mid hexagon-warning">
                                  $
                                </div>
                              </div>
                              <div className="pl-4">
                                <h4 className="font-weight-bold text-danger mb-0">$ {format_number(TotalFlete)}</h4>
                                <h6 className="text-muted">Total Flete Entregados</h6>
                                { LoadingT  &&
                                    <LinearProgress />
                                }
                              </div>
                            </div>
                         </div>
                      </div>  
                      

                  </div>
                </div>
             </div>          


            </div>
            </div>
    </>
  )
}

export default StatusGuias