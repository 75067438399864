
import { Button, FormControl, LinearProgress, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GetData } from '../../services/GetData'
import { Usuario } from '../../services/Usuario';
import Moment from 'moment';
import 'moment/locale/es';

const formatofecha = (fecha) => {
    const partesfecha = fecha.split("-");
    return  partesfecha[2] + "/" + partesfecha[1] + "/" + partesfecha[0]; //new Date(fecha).toLocaleDateString()
}

const ListarDespachos = () => {

    const today = new Date();
    const numeral = "#";

    const {	id_tipos_usuario, id_empleado } = Usuario();

    let month = today.getMonth() + 1;
    const year = today.getFullYear();
    const day = today.getDate();

    const fechaactual = year + "-" + month + "-" + day;

    let fecha = new Date(fechaactual);
    fecha.setDate(fecha.getDate() - 14);

    const fechaFinal = fecha.getFullYear() + "-" + (fecha.getMonth() + 1) + "-" + fecha.getDate();


    const [ListadoDespachos, setListadoDespachos] = useState([])
    const [LoadingTbl, setLoadingTbl] = useState(false) 
    const [Loading, setLoading] = useState(false) 
    const [Fecha_Inicial, setFecha_Inicial] = useState(fechaFinal)
    const [Fecha_Final, setFecha_Final] = useState(fechaactual)

    const [NumDespacho, setNumDespacho] = useState("") 
    const [TotalDespachos, setTotalDespachos] = useState(0) 
    const navigate = useNavigate();


    useEffect(() => {
      const today = new Date();
      const day = today.getDate().toString().padStart(2, '0');
      const month = (today.getMonth() + 1).toString().padStart(2, '0');
      const year = today.getFullYear();
    
      const fechaActual = `${year}-${month}-${day}`;
      setFecha_Final(fechaActual);
    
      const fechaInicial = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
      const fechaInicialFormatted = `${fechaInicial.getFullYear()}-${(fechaInicial.getMonth() + 1).toString().padStart(2, '0')}-${fechaInicial.getDate().toString().padStart(2, '0')}`;
      setFecha_Inicial(fechaInicialFormatted);

      console.log(fechaActual, fechaInicialFormatted)
    }, []);
    
     useEffect(() => {  
        listado_despachos(Fecha_Inicial, Fecha_Final);    
    }, []) 
    

    const diastrascurridas = (fecha_despacho) => {
      
      let hoy = Moment().format('YYYY-MM-DD');
      let fechad = Moment(fecha_despacho);
      const dias = fechad.diff(hoy, 'days'); 
      
      return parseInt(dias);    
    }

    const _handleKeyDown = (e) => {
      //  e.preventDefault();
        
            if (e.key === 'Enter') {          
                listado_despachos(Fecha_Inicial, Fecha_Final)
            }
        
        return 0;
    }

 
  
    const subtractTimeFromDate = (objDate, intHours) => {
        let fechahora = objDate + " " + intHours;
        let now = Moment(); //.format('MMM-DD-YYYY HH:mm:ss');
        let desp = Moment(objDate + " " + intHours)

        var duration = Moment.duration(now.diff(desp));
    

//        var horas = duration.asHours();

  //      console.log(horas)
        //let horas  = desp.diff(now, 'hours')

        //console.log(fechahora, "hora desp", desp, " fechahora ", now, horas);
         
        return duration._data.hours;

       
     
       // return newDateObj;

    }  

    const IrA = (id_despacho, fecha_despacho, hora_despacho) => {
      //const  dias = diastrascurridas(fecha_despacho)
      
    //  const horas = subtractTimeFromDate(fecha_despacho, hora_despacho);
    
      navigate('/home/verdespachos/'+id_despacho)
    
    }
    

    const listado_despachos = async (fechainicial, fechafinal) => {
        setLoadingTbl(true);

        console.log(fechainicial, fechafinal)
        setLoading(true);
       // console.log(mes,year)
        let urlapi = 'despachos/listadodespachosaux/';
        if (id_tipos_usuario === "6")
           urlapi = 'despachos/listadodespachosreexp/';

        await GetData(urlapi+fechainicial+'/'+fechafinal+'/'+NumDespacho+'/'+id_empleado).then ((result) =>{
              
              if (result.error === false){
                const lista = result.datos;
                                
                if (!lista){                  
                  setLoadingTbl(false);
                  return 0;
                }else{                  
                   
                    setTotalDespachos(lista.length);
                }


                let tabla = lista.map((item, index) => <tr key={index} onClick={()=>IrA(item.id_despacho, item.fecha_despacho, item.hora_despacho)}>
                  <td className="py-1 fondoazul">
                      {item.id_despacho}
                  </td>
                  <td className="py-1">
                      {formatofecha(item.fecha_despacho)}
                  </td>
                  <td  className="py-1">
                      {item.placa}
                  </td>
                  <td  className="py-1">
                      {item.cond}
                  </td>
                  <td  className="py-1">
                      {item.aux1}
                  </td>
                  <td  className="py-1">
                      {item.poblacion}
                  </td>
                  <td className="py-1">{item.reex ? item.reex : 'Ninguno'}</td>
                  <td className="py-1">{item.numruta}</td>
                  <td  className="py-1">
                      {item.usu}
                  </td>
                {/*   <td style={{display: id_tipos_usuario <= 3  ? '' : 'none' }} className='tabla_opcion' >
                        <Tooltip title={"Modificar Despacho "+item.id_despacho}>
                            <a href={numeral} onClick={() => IrAEditar(item.id_despacho)}><i class="mdi mdi-table-edit" aria-hidden="true"></i> Modificar</a>
                        </Tooltip>   
                 
                  </td> */}
                  
                
                </tr>)
                setListadoDespachos(tabla);
                setLoadingTbl(false);
                setLoading(false)
            }

        });
       
  }

  const ConsultarDespachos  = () => {
        listado_despachos(Fecha_Inicial, Fecha_Final); 
  }


  return (
    <>
      { Loading && <div className="loading">Loading&#8230;</div> }
    <div className='row'>
           <div className="col-lg-12 stretch-card">
                <div className="card">
                  <div className='card-title pl-4'>Listado de Despachos</div>  
                  <div className="card-body">

                    <div className='row '>
                        <div className='col-md-2 mb-2'>
                        <FormControl fullWidth>
                            
                            <TextField id="fecha_inicial" label="Fecha Inicial" 
                                variant="outlined" 
                                size="small" 
                                type="date"            
                                              
                                value={Fecha_Inicial}                   
                                 onChange={event => setFecha_Inicial(event.target.value)}   
                                />
                          
                          </FormControl>
                        </div>  
                        <div className='col-md-2 mb-2'>
                        <FormControl fullWidth>
                            
                            <TextField id="fecha_final" label="Fecha Final" 
                                variant="outlined" 
                                size="small" 
                                type="date"                                                        
                                value={Fecha_Final}  
                                
                                 onChange={event => setFecha_Final(event.target.value)}      
                               /*  onKeyDown={_handleKeyDown} */
                                />
                           
                          </FormControl>
                        </div>  
                        <div className='col-md-2 mb-2'>
                        <FormControl fullWidth>
                            <TextField id="standard-basic" label="Nº Despacho" 
                                variant="outlined" 
                                size="small" 
                                onChange={event => setNumDespacho(event.target.value)}
                                onKeyDown={_handleKeyDown}
                                />
                          </FormControl>
                        </div>  
                        <div className='col-md-2 mb-2'>
                            <Button onClick={ConsultarDespachos} color="secondary">Consultar</Button>
                        </div>
                    </div> 

                   

                    <div className="table-responsive">
                   
                      <table className="width200 table-hover">
                        <thead className='table100-head'>
                          <tr>
                            <th className='fondoazul'>Nº Despacho</th>
                            <th>Fecha</th>
                            <th>Vehículo</th>
                            <th>Conductor</th>
                            <th>Auxiliar 1</th>
                            <th>Municipio</th>
                            <th>Reexpedidor</th>
                            <th>{id_tipos_usuario === "5" ? 'En Ruta' : 'En Reexp' }</th>
                            <th>Usuario</th>
                            
                            
                          </tr>
                        </thead>
                        <tbody>
                          {ListadoDespachos}
                        </tbody>
                      </table>
                    </div>
                    <div className='mt-2'>
                    { LoadingTbl && <LinearProgress /> }
                    </div>
                    <div><span className="font-12 font-weight-semibold text-muted">Total Despachos: <strong>{TotalDespachos}</strong></span></div>
                  </div>
                </div>
              </div>
      </div>
    </>
  )
}

export default ListarDespachos