import React, { useEffect, useState } from 'react'
import { GetData } from '../../services/GetData';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import LinearProgress from '@mui/material/LinearProgress';
import { PostData } from '../../services/PostData';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

const Usuarios = () => {
    const numeral = "#";
    let DatosListaUsuario = []
    const [IdUsuario, setIdUsuario] = useState(null);
    const [TipoUsuario, setTipoUsuario] = useState([]);
    const [ListaUsuario, setListaUsuario] = useState([]);
    //const [DatosListaUsuario, setDatosListaUsuario] = useState([]);
    const [nombres, setNombres] = useState("");
    const [email, setEmail] = useState("");
    const [tipo, setTipo] = useState("");
    const [password, setPassword] = useState("");
    const [Empleado, setEmpleado] = useState(null);
    const [confPasswod, setConfPassword] = useState("");
    const [MDpassword, setMDPassword] = useState("");
    const [MDconfPasswod, setMDConfirPassword] = useState("");
    const [LoadingTbl, setLoadingTbl] = useState(false);  
    const [Loading, setLoading] = useState(false);  
    const [btnGuardar, setbtnGuardar] = useState(true);  
    const [openChangePassword, setopenChangePassword] = useState(false);  
    const [ListaEmpleadosAuxiliares, setListaEmpleadosAuxiliares] = useState([]);
    
    const MySwal = withReactContent(Swal)

  
      const cargar_tipos = () => {

        GetData('usuario/tiposusuario').then ((result) =>{
          let responseJSON = result;
          

          if (responseJSON.error === false){
              const listatipos = responseJSON.tipos;
              let listaselect = listatipos.map((item, key) => <option key={`op${key}`} value={item.id_tipo_usuario}>{item.nombre_tipo_us}</option>)
              setTipoUsuario(listaselect)
          }else{
        
        }
      })
      }

      useEffect(() => {
        GetData('empleados/empleadosauxiliares').then ((result) =>{          

          if (result.error === false){
              const lista_emp = result.empleados;
              let lista = lista_emp.map((item, key) => <option key={`op${key}`} value={item.id_empleado}>{item.nombre_empleado}</option>)
              setListaEmpleadosAuxiliares(lista)
          }else{
        
          }
        })

      }, [])
      


  
  const listado_usuarios = async () => {
        setLoadingTbl(true);

     
        GetData('usuario/listado').then ((result) =>{
              
              if (result.error === false){
                const listausuarios = result.usuarios;
                DatosListaUsuario = listausuarios;
                let tablausuarios = listausuarios.map((item, index) => <tr key={index}  onClick={() => llenarDatos(index)}>
                  <td className="py-1">
                      {item.nombre_usuario}
                  </td>
                  <td  className="py-1">
                      {item.email_us}
                  </td>
                  <td>{item.nombre_tipo_us}</td>
                  <td>
                        {item.estado_us === "1" ? <span>Activo</span> : <span className="badge text-bg-danger">Inactivo</span>}
                  </td>
                  
                  <td>{item.fecha_creado}</td>
                  <td>
       
                      <a href={numeral} onClick={() => delete_usuario(item.id_usuarios, item.nombre_usuario)}><i className="mdi mdi-delete-forever" aria-hidden="true"></i></a>
                  </td>
                </tr>)
                setListaUsuario(tablausuarios);
                setLoadingTbl(false);
            }

        });
       
/*
        await fetch('/usuario/listado', defaultOptions)
        .then(responseJson => {
              const resultado = responseJson.json();
              console.log("*********", resultado)
             
              
        })
        .catch(error => {
          setLoadingTbl(false);
          
      });
      */
/*
        GetData('/usuario/listado').then ((result) =>{
          let responseJSON = result;
          
          if (responseJSON.error === false){
              const listausuarios = responseJSON.usuarios;
              let tablausuarios = listausuarios.map(item => <tr>
                <td className="py-1">
                    {item.nombre_usuario}
                </td>
                <td  className="py-1">
                     {item.email_us}
                </td>
                <td>
                      {item.estado_us === "1" ? <button type="button" class="btn btn-success"> Activo </button> : <span class="badge text-bg-danger">Inactivo</span>}
                </td>
                <td>{item.fecha_creado}</td>
                <td>
                <a href={numeral}>
                            <i class="mdi mdi-file-check"></i>Editar
                          </a>
                    <a href={numeral}><i class="mdi mdi-delete-forever" aria-hidden="true"></i>Borrar</a>
                </td>
              </tr>)
              setListaUsuario(tablausuarios);
              setLoadingTbl(false);
          }else{

        }
*/

    
  }

  useEffect(() => {
      validar_guardardo()
  }, [tipo, password, nombres, email, confPasswod, password])
  

  const llenarDatos = (index) => {
         const datousuario =  DatosListaUsuario[index];
         setIdUsuario(datousuario.id_usuarios);
         setTipo(datousuario.id_tipos_usuario) 
         setNombres(datousuario.nombre_usuario);
         setEmail(datousuario.email_us)
  }

  const validar_guardardo = () => {
      if (IdUsuario === null){
          if (tipo !== "" && password !== "" && nombres !== ""  && email !== ""  && confPasswod !== "" && password !== confPasswod)
              setbtnGuardar(false)
      }else{
          if (tipo !== "" && nombres !== ""  && email !== "")
              setbtnGuardar(false)
      }
      return 0;
  }

  const limpiar = () => {
        setIdUsuario(null);
        setNombres("");
        setEmail("");
        setTipo("");
        setPassword("");
        setConfPassword("")
        setMDPassword("")
        setMDConfirPassword("")
        setEmpleado(null)
  }
  const guardarUsuario = () => {

          const data = {
            id_usuario : IdUsuario,
            nombres,
            email,
            tipo,
            password,
            Empleado
        }
        setLoading(true) 
      

        PostData('/usuario/guardar', data).then ((result) =>{
            let responseJSON = result;
            if (responseJSON.error === false){                
                setLoading(false) 
                MySwal.fire({
                  title: 'Guardar Usuario',
                  text: responseJSON.mensaje,
                  icon: 'success'
                  }
                )
                listado_usuarios();
                limpiar();
            }else{
             // setError(true);
              setLoading(false) 
              MySwal.fire({
                title: 'Guardar Usuario',
                text: responseJSON.mensaje,
                icon: 'error'
                });
          }
        })
        
  }

  const cambiarPassword = () => {
        const data = {
          id_usuario : IdUsuario,
          password
      }
      setopenChangePassword(false)
      setLoading(true) 


  PostData('/usuario/cambiarpassword', data).then ((result) =>{
      let responseJSON = result;
      if (responseJSON.error === false){                
          setLoading(false) 
          MySwal.fire({
            title: 'Guardar Usuario',
            text: responseJSON.mensaje,
            icon: 'success'
            }
          )
          limpiar();
      }else{
       // setError(true);
        setLoading(false) 
        MySwal.fire({
          title: 'Guardar Usuario',
          text: responseJSON.mensaje,
          icon: 'error'
          });
    }
  })
  

  }

  const borrar_usuario = (id_usuario) => {
    setLoadingTbl(true);

    GetData('/usuario/delete/'+id_usuario).then ((data) =>{
          if (data.error === false){
              listado_usuarios();
          }
    });
  }

  const delete_usuario = (id_usuario, nombre) => {
    MySwal.fire({
      title: 'Borrar usuario ',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Si',
      denyButtonText: `No`,
      text: "Esta segúro de borrar el usuario: " + nombre,
      }).then((result) => {
        if (result.isConfirmed) {
             borrar_usuario(id_usuario);
        }
      })

  }

  const cambiar_password = () => {
      setopenChangePassword(true)
  }
 
  const handleClose = () => {
    setopenChangePassword(false);
  };

  useEffect(() => {
      cargar_tipos();
      listado_usuarios();
  }, [])
  
  useEffect(() => {
     if (tipo !== 5){
         setEmpleado(null)
     }
  }, [tipo])
  

  return (
    <>
     { Loading && <div className="loading">Loading&#8230;</div> }
    <div className="row">
              <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">{IdUsuario === null ? 'CREAR USUARIO' : 'ACTUALIZAR USUARIO'}</h4>
                    <p className="card-description">Digite la información del usuario</p>

                    <form className="forms-sample" autoComplete="off">
                    <div className='form-row'>
                      <div className="form-group  col-6">
                        <label htmlFor="exampleInputEmail1">Tipo de usuario</label>
                        {/* <Select options={TipoUsuario} id="id_tipo_usuario" onChange={event => setTipo(event.target.value)}  /> */}
                         <select name="tipo" 
                        onChange={event => setTipo(event.target.value)} 
                        value={tipo}
                        className="form-control form-control-sm" id="exampleFormControlSelect3">
                          <option>Seleccione</option>
                          {TipoUsuario}
                        </select> 
                      </div>

                      <div className="form-group col-12">
                        <label htmlFor="exampleInputUsername1">Nombres y Apellidos</label>
                        <input name="nombres_apellidos"
                        onChange={event => setNombres(event.target.value)} 
                        value={nombres}
                        autoComplete="off"
                        type="text" className="form-control form-control-sm" id="exampleInputUsername1" placeholder="Escribe el nombre del usuarios" required />
                      </div>
                      <div className="form-group  col-12">
                        <label htmlFor="exampleInputEmail1">Correo Electrónico</label>
                        <input name="email_usuario"
                        onChange={event => setEmail(event.target.value)} 
                        value={email}
                        autoComplete="off"
                         type="email" className="form-control form-control-sm" id="exampleInputEmail1" placeholder="Correo Electrónico" required />
                      </div>
                      
                      <div className="form-group  col-md-6" style={{display: IdUsuario === null ? '' : 'none' }} >
                        <label htmlFor="exampleInputPassword1">Contraseña</label>
                        <input name="password_usuario"
                         value={password}
                         onChange={event => setPassword(event.target.value)} 
                        type="password" className="form-control form-control-sm" id="exampleInputPassword1" placeholder="Contraseña" required />
                      </div>
                      <div className="form-group  col-md-6" style={{display: IdUsuario === null ? '' : 'none' }}>
                        <label htmlFor="exampleInputConfirmPassword1">Confirmar Contraseña</label>
                        <input type="password" 
                        value={confPasswod}
                        onChange={event => setConfPassword(event.target.value)} 
                        className="form-control form-control-sm" id="exampleInputConfirmPassword1" placeholder="Confirmar contraseña" required />
                      </div>
                      <div className='form-group col-md-12'>
                          <button type="button" className="btn btn-danger mr-2" 
                                style={{display: IdUsuario !== null ? '' : 'none' }}
                                onClick={()=>cambiar_password()}
                                > Cambiar Contraseña </button>

                      </div>
                      

                      {tipo === '5' && 
                        <div className="form-group  col-6">
                          <label htmlFor="sel_empleado">Seleccione el Empleado</label>
                          <select name="empleado" 
                              onChange={event => setEmpleado(event.target.value)} 
                              value={Empleado}
                              className="form-control form-control-sm" id="sel_empleado">
                              <option>Ninguno</option>
                              {ListaEmpleadosAuxiliares}
                          </select> 
                        </div>
                      }
                    </div>
                      <button type="button" className="btn btn-primary mr-2" 
                         disabled={btnGuardar ? true : false}  
                      onClick={guardarUsuario}> Guardar </button>
                      <button className="btn btn-light" onClick={limpiar}>Nuevo</button>
                    </form>

                  </div>
                </div>
              </div>
      </div>
      <div className='row'>
           <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Listado de Usuarios</h4>                    
                    <div className="table-responsive">
                      
                      <table className="table100 table-hover">
                        <thead className='table100-head'>
                          <tr key={45454}>
                            <th>Nombres</th>
                            <th>Email</th>
                            <th>Tipo Us.</th>
                            <th>Estado</th>
                            <th>Fecha Registro</th>
                            <th>Opciones</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ListaUsuario}
                        </tbody>
                      </table>
                    </div>
                    <div className='mt-2'>
                    { LoadingTbl && <LinearProgress /> }
                    </div>
                  </div>
                </div>
              </div>
      </div>

            <Dialog open={openChangePassword} onClose={handleClose}>
              <DialogTitle>Cambiar Contraseña</DialogTitle>
              <DialogContent>            
                <TextField
                  autoFocus
                  margin="dense"
                  id="md_password"
                  label="Contraseña"
                  type="password"
                  fullWidth
                  variant="standard"
                  value={MDpassword}
                  onChange={event => setMDPassword(event.target.value)} 
                />
                <TextField
                  autoFocus
                  margin="dense"
                  id="md_confirpassword"
                  label="Confirmar Contraseña"
                  type="password"
                  fullWidth
                  value={MDconfPasswod}
                  variant="standard"
                  onChange={event => setMDConfirPassword(event.target.value)} 
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button onClick={cambiarPassword} color="error" disabled={MDpassword !== "" && MDpassword === MDconfPasswod  ? false : true}   >Cambiar Contraseña</Button>
              </DialogActions>
            </Dialog>

    </>
  )
}

export default Usuarios