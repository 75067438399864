import React, { useEffect, useState } from 'react'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {Usuario} from '../../../services/Usuario'
import { Link } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';

const Navbar = ({cerrar_sesion, handleOpenNuevaGuia}) => {
    const numeral = "#";
    const usuario = Usuario()
    const navigate = useNavigate();
    /* const [OpenMenu, setOpenMenu] = useState(false)

    const AbrirMenu = () => {          
          setOpenMenu(!OpenMenu)
    }

    useEffect(() => {
        const menuToggle = document.getElementById("sidebar");        
        if (menuToggle.classList.contains("active")){            
            menuToggle.classList.remove("active");
        }else{            
            menuToggle.classList.add("active");
        }
    }, [OpenMenu]) */

    const IrA = (url) => {
      navigate(url, { replace: true })
      return 0;
    }


  return (
    <>
        {/*  partial:partials/_navbar.html  */}
        <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
          <div className="navbar-menu-wrapper d-flex align-items-stretch">
            <button id="menu-toggle" className="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
              <span className="mdi mdi-chevron-double-left"></span>
            </button>
            <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
              <a className="navbar-brand brand-logo-mini" href="index.html"><img src="/img/logo_unirex_sm.png" alt="logo" className='logo_sm' /></a>
            </div>
           
            <ul className="navbar-nav navbar-nav-left">
                <li className="nav-item nav-logout d-none d-md-block mr-3" key={888888}>
                  <button type="button" className="btn btn-danger"
                                            onClick={handleOpenNuevaGuia}                                            
                                        >Nueva Guía</button>
                 </li>
            </ul>      

             <ul className="navbar-nav navbar-nav-right">
           
              <li className="nav-item nav-logout d-none d-md-block mr-3">
                  <strong>{usuario.nombre_usuario}</strong>
              </li>
              <li className="nav-item nav-profile dropdown show">
                <a className="nav-link" id="profileDropdown" href={numeral} data-toggle="dropdown" aria-expanded="true">
                    <div className="nav-profile-img">
                      {/* <img src="/img/face1.jpg" alt="image"  /> */}
                      <AccountCircleIcon />
                    </div>
                    <div className="nav-profile-text">
                      <span className="font-13 online-color"><i className="mdi mdi-chevron-down"></i></span>
                    </div>
                  </a>
                  
                <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
                    <a className="dropdown-item" href={numeral}>
                      <i className="mdi mdi-cached mr-2 text-success"></i> Registro Actividad </a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href={numeral}  onClick={cerrar_sesion}>
                      <i className="mdi mdi-logout mr-2 text-primary"></i> Cerrar Sesion </a>
                  </div>


              </li>
              <li className="nav-item nav-profile dropdown show">
                <a className="nav-link" id="profileDropdown" href={numeral} data-toggle="dropdown" aria-expanded="true">
                    <div className="nav-profile-img">
                       <MenuIcon />
                    </div>
                    <div className="nav-profile-text">
                      <span className="font-13 online-color"><i className="mdi mdi-chevron-down"></i></span>
                    </div>
                  </a>
                  
                <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
                    <a href={numeral} className='dropdown-item' onClick={() => IrA('/home')}>
                      <i className="mdi mdi-home mr-2 text-success"></i> Inicio </a>
                    <div className="dropdown-divider"></div>
                    <a href={numeral} className='dropdown-item' onClick={() => IrA('/home/coleccion')}>
                      <i className="mdi mdi-briefcase-check mr-2 text-primary"></i> Coleccion 
                    </a>
                    <div className="dropdown-divider"></div>
                    <a href={numeral} className='dropdown-item' onClick={() => IrA('/home/listadodespachos')}>
                      <i className="mdi mdi-package-variant mr-2 text-primary"></i> Despachos 
                      </a>  
                  </div>


              </li>
            </ul> 

            {/* <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas" onClick={AbrirMenu}>
              <span className="mdi mdi-menu"></span>
            </button> */}
          </div>
        </nav>
    </>
  )
}

export default Navbar