import React from 'react'
import StatusGuias from './partials/status_guias'
import { Usuario } from '../../services/Usuario';

const Home = () => {
  const {	id_empleado, id_tipos_usuario, nombre_usuario } = Usuario();

  return (
    <>
       <p className='pl-4'>
          <span>Bienvenido </span> <strong>{nombre_usuario}</strong>
       </p>
       <StatusGuias id_empleado={id_empleado} id_tipos_usuario={id_tipos_usuario} />
       
    </>
  )
}

export default Home