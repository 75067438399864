import React, { useEffect, useState, useContext } from 'react'
import './login.css'
import  { useNavigate } from 'react-router-dom'
import {PostData} from '../../services/PostData';
import { Usuario } from '../../services/Usuario';
import { UserContext } from '../../components/UserContext';

const Login = () => {

  const { login } = useContext(UserContext);
  const [usuario, setUsuario] = useState("");  
  const [password, setPassword] = useState("");  
  const [Redirect, setRedirect] = useState(false);  
  const [Error, setError] = useState(false);  
  const [Loading, setLoading] = useState(false);  
  
  const navigate = useNavigate();

  useEffect(() => {
        const usuario = Usuario();
        
        if (usuario !== null){
            navigate('/home');
        }
  }, [])

  useEffect(() => {
    setError(false)
  }, [usuario, password])
  

  const valirdar_usuario = async () => {
    const data = {
        usuario,
        password
    }
    setLoading(true) 
  
    PostData('login', data).then ((result) =>{
        let responseJSON = result;
        if (responseJSON.error === false){
            localStorage.setItem('userData',JSON.stringify(responseJSON));      
            const userData = {
                id_usuario: responseJSON.id_usuario,
                nombre_usuario: responseJSON.nombre_usuario,
                id_tipos_usuario: responseJSON.id_tipos_usuario,
                id_empleado: responseJSON.id_empleado,
                token: responseJSON.token,
                acceso: responseJSON.acceso
            }      
            login(userData);
            setLoading(false) 
            navigate('/home');
        }else{
          setError(true);
          setLoading(false) 
      }
    })
   }

  console.log("Redirecccionado ", Redirect )
  return (
    <>
        
        <section className="login p-fixed d-flex text-center bg-primary common-img-bg">
      
      { Loading && <div className="loading">Loading&#8230;</div> }

        <div className="container">
            <div className="row">
                <div className="col-sm-8">

                </div>
                <div className="col-sm-4">
                    
                    <div className="login-card card-block auth-body mr-auto ml-auto">
                        { Error && <div className="alert alert-danger" role="alert">
                                                    Error! Verifique su usuario y contraseña
                                    </div>
                        }
                        <form className="md-float-material" >
                            
                            <div className="auth-box">
                                <div className="row m-b-20">
                                    <div className="col-md-12">
                                        <div className="text-center">
                                            <img  src="img/logo_unirex.png" alt="logo.png"  height="57" />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="input-group">
                                    <input type="email" className="form-control" 
                                            onChange={event => setUsuario(event.target.value)} 
                                            placeholder="Ingresa su e-mail" value={usuario} 
                                            />
                                    <span className="md-line"></span>
                                </div>
                                <div className="input-group">
                                    <input type="password" className="form-control" 
                                         onChange={event => setPassword(event.target.value)}  
                                        placeholder="Contraseña" value={password} 
                                        />
                                    <span className="md-line"></span>
                                </div>
                                <div className="row m-t-25 text-left">
                                    <div className="col-sm-7 col-xs-12">
                                        <div className="checkbox-fade fade-in-primary">
                                            <label>
                                                <input type="checkbox" value="" />
                                                <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-primary"></i></span>
                                                <span className="text-inverse">Recordarme</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-5 col-xs-12 forgot-phone text-right">
                                       
                                    </div>
                                </div>
                                <div className="row m-t-30">
                                    <div className="col-md-12">
                                        <button type="button" disabled={password === "" || usuario === "" ? "true" : ""}  
                                            className="btn btn-primary btn-md btn-block waves-effect text-center m-b-20"
                                            onClick={valirdar_usuario}
                                            >Ingresar</button>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-md-10">
                                        <p className="text-inverse text-left m-b-0">UNIREX S.A.S</p>
                                        <p className="text-inverse text-left"><b>Empresa de Trasportes</b></p>
                                    </div>
                                    <div className="col-md-2">
                                      
                                    </div>
                                </div>

                            </div>
                        </form>
                        
                    </div>
                    
                </div>
                
            </div>
            
        </div>
        
    </section>
    </>
  )
}

export default Login;